/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { Input, HostBinding, Directive, Renderer2, ElementRef, NgZone, NgModule } from '@angular/core';
import { register } from '@progress/kendo-ripple';
import { isDocumentAvailable } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-ripple',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1635940099,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * Represents the Ripple container component.
 *
 * You can apply this directive to any container element.
 * The ripple effect will show on the following elements:
 * - Buttons
 * - Checkboxes
 * - Radio buttons
 *
 * @example
 * ```ts-no-run
 * <div kendoRippleContainer>
 *     <button kendoButton>Default Button</button>
 *
 *     <button kendoButton [primary]="true">Primary Button</button>
 * </div>
 * ```
 */
let RippleContainerDirective = class RippleContainerDirective {
    constructor(renderer, element, ngZone) {
        this.renderer = renderer;
        this.element = element;
        this.ngZone = ngZone;
        this.isDisabled = false;
        this.removeListeners = () => { };
        validatePackage(packageMetadata);
    }
    /**
     * Provides an option to disable the ripple effect of the `kendoRippleContainer` element.
     * By default, `disabled` is set to `false`.
     */
    set disabled(disabled) {
        this.isDisabled = disabled;
        if (this.isDisabled) {
            this.removeListeners();
        }
        else {
            this.registerListeners();
        }
        this.renderer.setProperty(this.element.nativeElement, 'disabled', disabled);
    }
    get containerClass() {
        return true;
    }
    ngOnDestroy() {
        this.removeListeners();
    }
    ngAfterViewInit() {
        if (!this.isDisabled) {
            this.ngZone.runOutsideAngular(() => {
                this.registerListeners();
            });
        }
    }
    registerListeners() {
        if (!isDocumentAvailable()) {
            return;
        }
        this.removeListeners();
        const callback = register(this.element.nativeElement, [
            { selector: ".k-button:not(li)" },
            { selector: ".k-list>.k-item", options: { global: true } },
            { selector: ".k-checkbox,.k-radio",
                options: {
                    events: ["focusin", "animationend", "click"]
                }
            }
        ]);
        this.removeListeners = callback;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], RippleContainerDirective.prototype, "disabled", null);
__decorate([
    HostBinding('class.k-ripple-container'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], RippleContainerDirective.prototype, "containerClass", null);
RippleContainerDirective = __decorate([
    Directive({
        selector: '[kendoRippleContainer]'
    }),
    __metadata("design:paramtypes", [Renderer2, ElementRef, NgZone])
], RippleContainerDirective);

const COMPONENT_DIRECTIVES = [RippleContainerDirective];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Ripple directive.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Ripple module
 * import { RippleModule } from '@progress/kendo-angular-ripple';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, RippleModule], // import Ripple module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let RippleModule = class RippleModule {
};
RippleModule = __decorate([
    NgModule({
        declarations: [COMPONENT_DIRECTIVES],
        exports: [COMPONENT_DIRECTIVES]
    })
], RippleModule);

/**
 * Generated bundle index. Do not edit.
 */

export { RippleContainerDirective, RippleModule };
